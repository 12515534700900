
<app-navigation [appUser]=appUser active="profile" ></app-navigation>


<div class="container-md mb-5">
        <form [formGroup]="pForm" class="form-horizontal" *ngIf="appUser">    
         <div class="card">   
            <div class="card-header d-flex">   
                <div class="w-75">
                    <h3 class="mb-0"><i class="collapsable fa ${appUser.groupAdmin ? 'fa-user-circle-o' : 'fa-id-card-o'}" aria-hidden="true"></i> 
                        <span class="collapsable">Profile </span> <span data-toggle="tooltip" class="{{appUser.activated ? 'text-dark' : 'text-danger'}}" title="{{appUser.activated ? '' : 'Email address not verified, can not recieve system emails such as password-reset.'}}"><span class="explodable" title="${appUser.email}">{{appUser.email}}</span></span></h3>
                    
                </div>
                <div class="w-25 text-right lh-2">
                    
                    <span *ngIf="appUser.groupAdmin" class="badge badge-danger badge-pill mr-2">admin</span>
                    <small *ngIf="appUser.userStatus != null && appUser.userStatus != 'CONFIRMED'" class="text-warning" th:title="${appUser.userStatus}"><span class="collapsable-sm">{{appUser.userStatus}}</span></small>
                    <span *ngIf="appUser.userStatus != null && appUser.userStatus == 'CONFIRMED'" class="text-success"  title="You have been authorized to log-in to this and other Synthetron apps."><i class="fa fa-cloud" aria-hidden="true" data-toggle="tooltip"></i><span class="collapsable-sm"> Authorized</span></span>
                </div>

             
 
         </div>                 
        <div class="card-body">
        <div class="row">
        <div class="col-lg-9"> 
            
             
            
            <div class="form-group row text-nowrap">
                <label for="inputFirstName" class="col-form-label col-sm-4">First Name</label>
                <div class="col-sm-8">
                    <input type="text" required="true" class="form-control" id="inputFirstName" placeholder="First Name" 
                    [(ngModel)]="firstName" name="firstName"/>
                </div>
            </div>

            <div class="form-group row text-nowrap">
                <label for="inputLastName" class="col-form-label col-sm-4">Last Name</label>
                <div class="col-sm-8">
                    <input type="text"  required="true" class="form-control" id="inputLastName" placeholder="Last Name" 
                   [(ngModel)]="lastName" name="lastName"/>
                </div>
            </div>
            
            <div class="form-group row">
                <label for="phone" class="col-form-label col-sm-4"><span title="This field is required when 2-fa authentication is enabled." data-toggle="tooltip">Mobile phone</span></label>
                <div class="col-sm-8">
                    <input autocomplete="false" type="tel" required="" class="form-control" placeholder="include country code: +31 6 55555555" 
                    [(ngModel)]="phone" name="phone"/>
                </div>
            </div> 
            <div class="form-group row">
                <label for="inputActive" class="col-form-label col-sm-4 text-nowrap"><span title="Turn 2-fa authentication on (recommended) or off. If enabled, mobile number is required." data-toggle="tooltip">2-Factor Authentication</span></label>
                <div class="col-sm-8">
                    <input type="checkbox" data-toggle="toggle" data-onstyle="primary" data-offstyle="outline-secondary" value="true" id="inputActive"
                    [(ngModel)]="mfa" name="mfa"/>
                </div>
            </div>

            <div class="form-group row text-nowrap">
                <label for="inputCountry" class="col-form-label col-4"><span>Country</span></label>
                <div class="col-8">

                    <input  required="" class="form-control" readonly
                    [(ngModel)]="country" name="country"/>


    
                </div>
            </div>
            
            <div class="form-group row">
            <label for="timeZone" class="col-form-label col-4">Time Zone</label>
                <div class="col-8">
                    <input  required="" class="form-control" readonly
                    [(ngModel)]="timeZone" name="timeZone"/>         

                </div>
            </div>  
            
            <div class="form-group row" *ngIf="appUser.groupAdmin">
            <label class="col-form-label col-4" title="This app has a high-contrast mode available to admins. Non admin Users don't have nor see shis feature. To enable/disable change the global theme setting of your device and Go will follow along seemlessly." data-toggle="tooltip">Dark Mode</label>
                <div class="col-8">
                    <span class="dark-mode-enabled"></span>
                </div>
      
            </div>  


            <div class="form-group row">
                <label for="inputRole" class="col-form-label col-sm-4 text-nowrap">Your Role</label>
                <div class="col-sm-8">
                    
                    <div class="alert"> 
   
                    <div *ngFor="let r of appUser.roles">           
                        <label for="{{r}}" class="form-check-label" *ngIf="r != 'ROLE_OBSERVER'">     
                            <span >{{r}}</span>
                        </label>

                    </div>    
                    </div>
                </div>
            </div>   
            
            <div > 
                <a name="group"></a>
                <div class="form-group row">
                 <label for="" class="col-form-label col-sm-4">
                     <span title="You are a member of multiple organisation groups." data-toggle="tooltip">Admin of groups</span></label>
                 <div class="col-sm-8">
                    <div class="alert"> 
                   
                        <ol class="mb-0 pl-3">
                            <li *ngFor="let t of appUser.groups">
                                <span class="btn btn-link p-1">{{t.name}}</span> </li>
                        </ol>

                        <small hidden class="pull-right text-dark" *ngIf="appUser.systemAdmin">switch groups here or on the <a href="/">homepage</a></small>
                         <div class="clearfix"></div>
                    </div>
                 </div>
                </div>
            </div>

           <div class="form-group row">
             <label for="" class="col-form-label col-sm-4 text-nowrap">
                 <span title="Organisations you are assigned to." data-toggle="tooltip">Your memberships</span></label>
             <div class="col-sm-8">

                   <table class="table table-primary rounded mb-0 table-sm">
                     <thead class="thead-secondary">
                     <tr>
                         <th scope="col" class="border-top-0" width="60%">Organisation</th>   
                         <th scope="col" class="border-top-0" width="40%"></th>            
                     </tr>
                     </thead>
                     <tbody>

                     <tr *ngFor="let memb of appUser.accounts" data-placement="left">
                         <th *ngIf="memb.membershipType != membershipType.MEMBER">
                             <a 
                                title="Click to view and edit this account." 
                                class="{{memb.activeMembership ? 'text-primary' : 'text-dark'}}">
                                 <span class="text-capitalize">{{memb.name}}</span></a></th>
                         <th *ngIf="memb.membershipType == membershipType.MEMBER"><span class="text-capitalize" >{{memb.name}}</span></th>
                         <td>{{memb.membershipType}}</td> 

                     </tr>

                     </tbody>
                 </table>
                 </div></div>
            

        </div></div></div>
             <div class="card-footer text-right">
                <a  href="/" class="btn btn-outline-secondary mr-2"><i class="fa fa-undo" aria-hidden="true"></i> Back</a> 
                <button type="submit" value="Save" class="btn btn-primary"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
            </div>            
         
         </div>
    </form>
    
</div>
