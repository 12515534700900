import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment}  from '../environments/environment';
import { Discussion } from './model/discussion';
import { TimeRange } from './model/idiscussion';
import { RestAPI } from '@aws-amplify/api-rest';
import { Amplify,Auth } from 'aws-amplify';
import awsExports from '../aws-exports';

var myInit = { 
  apiTimeout : 99,
  body : {},
  headers: {'x-api-key':environment.xApiKey,'Access-Token':''}, 
  response: true
};

@Injectable({
  providedIn: 'root',
})
export class ConversationApiService {
  conversation!: Discussion;
  timeRanges=TimeRange; 
  constructor() {

  }
  
  initToken() {
    Amplify.configure(awsExports);
    Auth.currentSession()
      .then(session => {
        myInit.headers['Access-Token']= session.getIdToken().getJwtToken();
        console.log('jwt token obtained');
     });
  }
  public getDiscussion(id:string)  {

    const discussion = new Observable<Discussion>(observer => {
      setTimeout(() => {
        console.log("fetching discussion: " +id);
        
        RestAPI.get('GoDiscussionGw', '/discussion/'+id,myInit)
          .then(response => {
            this.conversation = response.data
            observer.next(this.conversation)
            observer.complete()
          })
          .catch(error => {
            var em = "error fetching discussion: "+ error;
            observer.error(em);
            console.log(em);
          });

        },myInit.apiTimeout);
  });

  return discussion;
  }

  public getDiscussions(timeRange:string, accountId:string)  {

    console.log("Getting discussions for " +accountId+", with timerange "+ timeRange);
    const duscussions = new Observable<Discussion[]>(observer => {
      setTimeout(() => {
        RestAPI.get('GoDiscussionGw', '/discussion/account/'+accountId+'/timerange/'+timeRange,myInit)
          .then(response => {
            observer.next(response.data)
            observer.complete()
          })
          .catch(error => {
            var em = "no discussions found: "+ error;
            if(error.response?.status == 404) {
             observer.next([]);
              observer.complete();
            } else {
            em = "error fetching discussions: "+ error;
            observer.error(em);
            }
            console.log(em);
        });

  },myInit.apiTimeout);
  });
  return duscussions;
  }

}
