
<app-navigation  [appUser]=appUser [selectAccountId]=selectAccountId  (selected)="onSelected($event)"></app-navigation>
    
    <div class="container-lg">
        <h3>Welcome <span class="text-capitalize">{{appUser?  appUser.firstName : ''}}</span><span class="collapsable-sm">, to Synthetron Go Angular</span></h3>        
        <div class="row row-cols-1 row-cols-lg-2 mt-2">
        <div class="col-xl-7 mb-4">    
          <div class="card h-100 described">
          <div class="card-header bg-primary text-white d-flex align-items-center ">        
              <div class="w-50">
              <h6 class=" mb-0" *ngIf="discussions != undefined"><i class="fa fa-fw fa-calendar-check-o" aria-hidden="true"></i> <strong >{{discussions.length}}</strong> Planned <span class="collapsable-sm">Discussions</span></h6>
              </div>
              <div class="w-50 text-right">
                <app-discussion-search-box [appUser]=appUser></app-discussion-search-box>
              </div>
          </div>  
            <div class="card-body">
                <div class="alert alert-secondary" *ngIf="discussions.length > 0" role="alert">
                    <i class="fa fa-lightbulb-o" aria-hidden="true"></i> click a discussion for details and actions.</div>    
                <ul class="list-group list-group-flush" >
                  <li class="list-group-item pl-1 pr-1"  *ngFor="let disc of discussions" (click)="navigateToDashboard(disc.id)">
                   <div class="row no-gutters align-items-center clickable">
                    <div class="font-weight-bold col-4 div-overflow" title="{{disc.label || disc.key}}">{{disc.label || disc.key}}</div>
                    <div class="text-capitalize col-4 div-overflow">{{disc.account.name}}</div>
                    <div class="col-4 div-overflow">{{disc.startDate | date:'dd MMM - HH:mm' }}</div>
                    </div>
                  </li>
                </ul> 
                
                <p *ngIf="noResults">
                            You don't have any planned discussions. <br>Take a look at your <a routerLink="/discussions/" title="View all your Discussions"  class="">discussions list</a> or add a new discussion.  
                    
                </p>
                
            </div>
            <div class="card-footer">
                <span title ="Add new Discussion" data-toggle="tooltip" class=" pl-0"><i class="fa fa-plus" aria-hidden="true"></i> New Discussion</span>

            </div>
          </div>
        </div>
        <div class="col-xl-5 mb-4">
           <div class="card h-100">
            <div class="card-header">
                <h6 class="mb-0 pull-left"><i class="fa fa-fw fa-building-o" aria-hidden="true"></i> Your organisations <span class="badge badge-primary">{{ appUser?.accounts?.length || 0}}</span>        </h6>           
            </div>

            <div class="card-body mh-lg">    
                <ul class="list-group list-group-flush" *ngIf="appUser">
                  <li class="list-group-item pl-1 pr-1" *ngFor="let acMemb of appUser.accounts" id="{{acMemb.id.accountId}}"> 
                    <div class="d-flex align-items-center">
                        <div class="w-25 d-table-cell w-25 text-center" title="add/update logo">
                                <img *ngIf="acMemb.logoUrl" src="{{acMemb.logoUrl}}" class="img-thumbnail" width="80" />
                                <div *ngIf="!acMemb.logoUrl" class="img-thumbnail placeholder"><i class="fa fa-picture-o text-secondary" aria-hidden="true"></i></div>
                        </div>
                        <div class="text-capitalize w-75 font-weight-bold div-overflow">{{acMemb.name}}</div>
                    </div>
                  </li>
                </ul>  

            </div>

            <div class="card-footer">
                
                <div class="w-50 pull-left">
                </div>
                <div class="w-50 pull-right text-right">
                    <small class="text-muted">Total: {{appUser?.accounts && appUser?.accounts?.length || 0}}</small>
                </div>
                <div class="clearfix"></div>
            </div>
          </div>    
            <div *ngIf="appUser && !appUser?.groupAdmin" class="card h-100">
            <div class="card-header">
                <h6 *ngIf="appUser.activeAccount != undefined" class="mb-0"><i class="fa fa-fw" aria-hidden="true"></i> <span class="text-capitalize">{{appUser.firstName}}</span>, you are <i class="text-lowercase">{{appUser.activeAccount.membershipType}}</i> of 
                    <i  class="text-capitalize">{{appUser.activeAccount.name}}</i>
                </h6>           
                <h6 *ngIf="appUser.activeAccount == undefined" class="mb-0"><i class="fa fa-fw fa-user-o" aria-hidden="true"></i> {{appUser.firstName}} you are a Member.</h6>  
            </div>
            <div class="card-body">    
                               
                <ul *ngIf="!appUser.accountCoOwner" class="circle">
                    <li>You can moderate discussions you're assigned to.</li>
                    <li>One of your team members can assigned you to discussions. </li>                    
                    <li>You only see discussions you moderate.</li> 
                 </ul>
                <ul *ngIf="appUser.accountCoOwner && !appUser.accountOwner" class="circle">
                    <li>You have full access to discussions in easy and advanced mode.</li>
                    <li>You have read-only access to the organisation &amp; members info.</li>
                 </ul>
                
                <ul *ngIf="appUser.accountOwner" class="circle">
                    <li>You're the person primarily responsible for <i class="text-capitalize">{{appUser.activeAccount && appUser.activeAccount.name || ''}}</i>.</li>
                    <li>You act as <i>administrator</i> within the organisation.</li>
                    <li>You can add new members and assign <i>co-owners</i>.</li>   
                    <li>You can remove members from the organisation.</li>   
                 </ul>
            </div>
          </div>     
        </div>
        </div>
    </div>
    
    <!-- <ng-container *ngIf="!!authenticator.user">
      <h2>Property, {{ authenticator.appUser.attributes?.email}}</h2>
      <h2>Property, {{ authenticator.appUser.associateSoftwareToken}}</h2>
    </ng-container> -->

