import { IAccount } from "./iaccount";
import { MediaAsset } from "./mediaAsset";
import { Landingpage } from "./landingpage";
import { Time } from "@angular/common";

export interface IDiscussion {
  id: string,

  profile? :Profile;
  server?: string;
  key?: string,
  label?:string,
  topic?:string,
  currentTopicId?:string,
  language:string,
  creator?:string,
  startDate?:Date,
  startTime?:Time,
  started?:Date,
  ended?:Date,
  duration?:number,
  groupSize?:string,
 // type:Type,
  modifiedDate?:Date,
  mode?:Mode,
  account?: IAccount,
  mediaAssets?: MediaAsset[],
  landingpages?: Landingpage[]

  
  
}

//export enum AllowSend {CHANGE_TOPIC,END_SESSION,NOT_RUNNING,PAUSED,TRUE}
export enum Mode {'NOT_STARTED','STARTED','ENDED'}
//export enum Type {OPEN,INVITATION}
export enum Profile {'DEBATE_EXPLORATORY','DEBATE_OPEN','DEBATE_TARGETED','EXPLORATORY','GENERAL','OPEN','TARGETED'}

export enum Topics {'Wellbeing','Inclusion','Energy'}
export enum Languages {'Dutch','French','XYZ'}
export enum TimeRange {FROM_TODAY = 'From today',
FROM_LAST_MONTH ='From last month',FROM_LAST_3_MONTHS = 'From last 3 months' , FROM_LAST_6_MONTHS = 'From last 6 months', HISTORIC= 'Historic', ALL= 'Ignore start date'}