export const environment = {
  production: false,
  staging: true,
  xApiKey : 'D1r6slFRXS5qsBNCvaKpA4mpHOlBkNn01zdJKZQa',

  go: { 
    group: {
      name: 'insi',
      id: 'c6cc79be-c1db-471f-b68a-f58345666162',
    },
    participantLink: 'https://staging.synthetron.com/account',
    moderatorLink: 'https://mod-staging.synthetron.com/home',
  },  

  endpoints: {
      GoUserGw:         "https://3ohruy9o5c.execute-api.eu-central-1.amazonaws.com/staging",
      GoDiscussionGw:   "https://ine46yxzs5.execute-api.eu-central-1.amazonaws.com/staging",
      GoLandingpageGw:  "https://1z7f782n3a.execute-api.eu-central-1.amazonaws.com/staging"
  }
  
};
