import { Component, OnInit, Input } from '@angular/core';
import { AppUser } from '../model/appUser';
import { Membership } from '../model/membership';
import { FormBuilder,FormGroup } from '@angular/forms';
import { MembershipType  } from '../model/imembership';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @Input() appUser= {} as AppUser;
  @Input() activeAccount?: Membership;

  pForm:FormGroup;
  membershipType=MembershipType;
  
  email ='';
  firstName='';
  lastName='';
  country='';
  phone='';
  mfa='';
  timeZone='';
  roles=[];

   constructor(private fb: FormBuilder) {
    this.pForm = this.fb.group({

      firstName: [],
      lastName: [],
      country: [],
      phone: [],
      mfa: [],
      roles: [],
      timeZone: []
    });
    }
   ngOnInit(): void {


  var o = localStorage.getItem('appUser');
  if(o != undefined && (this.appUser == undefined || this.appUser.email == undefined)) {
    this.appUser = JSON.parse(o);
    if(this.appUser.email != undefined){
     console.log('user profile stored locally');

     this.pForm.controls['firstName'].setValue(this.appUser.firstName);
     this.pForm.controls['lastName'].setValue(this.appUser.lastName);
     this.pForm.controls['country'].setValue(this.appUser.country);
     this.pForm.controls['phone'].setValue(this.appUser.phone);
     this.pForm.controls['mfa'].setValue(this.appUser.mfa);
     this.pForm.controls['roles'].setValue(this.appUser.roles);
     this.pForm.controls['timeZone'].setValue(this.appUser.timeZone);



     return;
    } 
  }
  

  this.pForm.controls['firstName'].valueChanges.subscribe(value => {
    console.log('firstName changed: '+ value);
  });


}

}
