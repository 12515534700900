<amplify-authenticator>


  <ng-template amplifySlot="sign-in-header">

    <div class="amplify-heading pb-0 pt-4"
    style="padding: var(--amplify-space-xl);"
  >
    <img
    class="login-image"
    alt="Synthetron logo"
    src="https://assets.synthetron.com/media/staging//842837c6-253f-4515-816b-cba1afa1e24f.png"
    />

      <h5 class="text-right text-secondary">Go <span class="text-primary">Admin webapp</span> Angular</h5> 
      <small class="text-secondary"><span class="text-primary">active AWS services</span> <i> Amplify, Cognito (SSO), API Gateway, Cloudfront,
        <br> RDS Aurora Serverless, <span title="Elastic Beanstalk">EB</span>, CodeCommit.</i></small>
        <small class="text-secondary"><span class="text-primary"> data sources</span> <i> Go User microservice, Go Discussion microservice</i></small><br>
    </div>
  </ng-template>


  <ng-template 
    amplifySlot="authenticated"
    let-user="user"
    let-signOut="signOut">
     


    <router-outlet></router-outlet> 
    
  </ng-template>
</amplify-authenticator>