import { Component, OnInit,Input } from '@angular/core';
import { AppUser } from '../model/appUser';
@Component({
  selector: 'app-discussion-search-box',
  templateUrl: './discussion-search-box.component.html',
  styleUrls: ['./discussion-search-box.component.scss']
})
export class DiscussionSearchBoxComponent implements OnInit {
  @Input() appUser?: AppUser;
  constructor() { }
  searchString = '';
  ngOnInit(): void {
  }

}
