import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppUser } from './model/appUser';


@Injectable() 
// @Injectable({
//   providedIn: 'root',
// })
export class StateService  {
  // Observable string sources
  private activeAccountSource = new Subject<string>();
  private appUserSource = new Subject<AppUser>();
  //private missionConfirmedSource = new Subject<string>();

  // Observable string streams
  activeAccount$ = this.activeAccountSource.asObservable();
  appUser$ = this.appUserSource.asObservable();

  // Service message commands
  emitActiveAccount(accountId: string) {
    this.activeAccountSource.next(accountId);
  }

  // Service message commands
  emitAppUser(appUser: AppUser) {
    this.appUserSource.next(appUser);
  }


}
