import { IUser } from "./iuser";
import { IAccount } from "./iaccount";
import { MembershipId } from "./membershipId";

export interface IMembership {
  id: MembershipId,
  name?: string;
  activeMembership: boolean,
  logoUrl:string,
  account?: IAccount,
  user?: IUser,
  membershipType:MembershipType,
  modifiedDate?:Date
}
export enum MembershipType {ADMIN,OWNER,COOWNER,MEMBER}