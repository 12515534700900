import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DiscussionComponent } from './discussion/discussion.component';
import { DiscussionsComponent } from './discussions/discussions.component';
import { ProfileComponent } from './profile/profile.component';

const standardRoutes: Routes = [
  
  { path: 'home', component: HomeComponent },
  { path: 'discussion/:id',  component: DiscussionComponent},
 
  { path: 'discussions', component: DiscussionsComponent},
  { path: 'profile',  component: ProfileComponent},
  { path: '**', component: HomeComponent },
  
];

@NgModule({
  //imports: [RouterModule.forRoot([])],
  imports: [
    RouterModule.forRoot(standardRoutes,{ bindToComponentInputs: true }),
    
  ],
  exports: [RouterModule],
    
})
export class AppRoutingModule {}
