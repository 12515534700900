<app-navigation [appUser]=appUser active="discussion"></app-navigation>

<div class="container-lg">
    <h3 class=" mb-3" *ngIf="disc.account != undefined">
      <i class="collapsable-sm fa fa-tachometer" aria-hidden="true"></i> Dashboard 
      <small><i class="fa fa-chevron-circle-right text-primary" aria-hidden="true"></i></small> 
        <span class="text-capitalize"> {{disc.account.name}} </span>
        <small> <i class="fa fa-chevron-circle-right text-primary" aria-hidden="true"></i></small> {{disc.key}}
        <small><small><span *ngIf="disc.clonedDate != null" class="badge badge-primary badge-pill" 
          title="on:{{disc.clonedDate | date:'dd MMM - HH:mm' }} cloned discussions only include basic info and reporting."><i class="fa fa-clone" aria-hidden="true"></i> cloned</span></small></small>
    </h3>  

    <div class="clearfix"></div>
    <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3">
    <div class="col mb-4">
        <div class="card h-100">
            <div class="card-header">
                <h6 class="mb-0"><i class="collapsable-lg fa fa-fw fa-comments text-success" aria-hidden="true"></i> <b>Session Details</b></h6>                   
        </div>
        <div class="card-body">        
            <ul class="list-group list-group-flush mb-1">                      
                <li class="list-group-item font-weight-bold">Date: {{disc.startDate | date:'dd MMM - HH:mm' }} </li>
                <li class="list-group-item">Language: {{disc.language == null ? '' : disc.language}} <small> {{disc.server}} server</small></li>
                <li class="list-group-item">Created by: <small>{{disc.creator}}</small></li>
                <li class="list-group-item">Status: {{disc.mode}}
                </li>           
          </ul>  
            
            <a *ngIf="!moderatorLink || disc.clonedDate != null" href="{{moderatorLink}}" target="blank"  title="Caution, this is the generic moderator link for this discussion. To make sure you have access, use the link under 'Copy Links'."  class="btn btn-sm btn-link  link-fw" data-toggle="tooltip">
                <i class="fa fa-fw fa-user-circle-o" aria-hidden="true"></i> Moderator App <small><i class="fa fa-external-link te" aria-hidden="true"></i></small></a>
            
            <a *ngIf="!moderatorLink || disc.clonedDate == null"  title="You are not a moderator of this discussion yet, click to add yourself or click 'Copy Session Links' for more options."  class="btn btn-sm btn-link text-secondary link-fw" data-toggle="tooltip">
                <i class="fa fa-fw fa-user-circle-o" aria-hidden="true"></i> Add Moderator</a>    
                
            <a *ngIf="disc.server && disc.account != undefined" href="https://{{disc.server}}/account/{{disc.account.name}}/gwt/{{disc.key}}" target="_blank"  title="Go to the Participant client."  class="btn btn-sm btn-link link-fw">
                <i class="fa fa-fw fa-comments-o" aria-hidden="true"></i> Participant Client <small><i class="fa fa-external-link" aria-hidden="true"></i></small></a>
        </div>
        <div class="card-footer">
            <div class="w-50 pull-left">
            <span data-toggle="tooltip" title="Edit the details of this Session and add Moderators."  ><i class="fa fa-pencil" aria-hidden="true"></i> edit session details</span>                    
            </div>               

            <div class="w-50 pull-right text-right">
                <small class="text-muted">Updated: <span *ngIf="disc.modifiedDate != null"> {{disc.modifiedDate | date:'dd MMM yyyy' }}</span> </small>
            </div>
            <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <div class="col mb-4">    
      <div class="card h-100">
      <div class="card-header bg-primary text-white">
          <h6 class="mb-0 pull-left"><i class="collapsable-lg fa fa-fw fa-clock-o" aria-hidden="true"></i> Invitation  </h6>
            <div class="btn-group pull-right" role="group">
            <ul class="nav nav-tabs card-header-tabs" *ngIf="disc.landingpages && disc.landingpages!.length < 2" role="tablist">
                <li role="presentation" *ngFor="let lp of disc.landingpages;index as i" class="nav-item">
                  <a class="nav-item nav-link {{i == 0 ? 'active' : ''}}" id="lpnav-{{i}}-tab" data-toggle="tab" href="#lpnav-{{i}}" role="tab"  aria-selected="true">Page {{i+1}}</a>
                  </li>
            </ul>         
            </div>
        </div>

        <div class="card-body tab-content">
            <p *ngIf="!disc.landingpages"><small>This session doesn't seem to have a Landingpage yet.</small></p>            
         <!--use tabs and tabcontent for each lp -->
            <div class="tab-pane fade show {{index == 0 ? 'active' : ''}}" *ngFor="let lp of disc.landingpages;index as index" id="lpnav-{{index}}"  role="tabpanel">
                <div *ngIf="messageSent" class="alert alert-dismissible shadow-sm alert-success" role="alert">
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                    <span>{{messageSent}}></span>
                </div>
               <a hidden href="/lp/nocache/{{lp.id}}"  *ngFor="let lp of disc.landingpages;index as index" target="blank" title="Don't give this link to participants, use links under 'Copy Links'." 
               class="btn btn-link  link-fw"><i class="fa fa-fw fa-globe" aria-hidden="true"></i> View Landingpage <span>{{index > 0 ? index +1 : ''}}></span> <small><i class="fa fa-external-link" aria-hidden="true"></i></small></a> 

                    <a *ngIf="disc.server" title="Try and copy links to this landingpage, the Participant app and the Moderator app." 
               class="btn btn-link"  
                    data-toggle="modal" data-target="#copyShortcutsModal"><i class="fa fa-fw fa-link" aria-hidden="true"></i> Copy Session Links</a>    
                    
                    <a  title="Send the invitation mails to participants, moderators and observers." 
                    class="btn btn-link" 
                 data-toggle="modal" data-target="#inviteMailModal"><i class="fa fa-fw fa-envelope-o" aria-hidden="true"></i> Send Session Links
                 </a>         
                <a  title="Add a new Landingpage" class="btn btn-link  link-fw"><i class="fa fa-fw fa-plus" aria-hidden="true"></i> Add Landingpage</a>
          </div> 
        </div>
       
        <div class="card-footer tab-content text-right ">
    
                <small class="text-muted">Landingpages: {{disc.landingpages?.length}}</small>
           </div>
       </div>   
     
    </div>    
    <div class="col mb-4">    
    <div class="card h-100">
      <div class="card-header bg-success text-white">
          <h6 class="mb-0 pull-left"> <i class="collapsable-lg fa fa-fw fa-file-excel-o" aria-hidden="true"></i> Reports</h6>
           <a *ngIf="appUser.groupAdmin" title ="Unlock report-generation links." data-toggle="tooltip" class="btn btn-outline-light btn-sm pull-right"><i class="fa fa-refresh" aria-hidden="true"></i></a>
           <div class="clearfix"></div>
      </div>
        <div class="card-body">
            <hr *ngIf="disc.forms">
            <span  *ngFor="let f of disc.forms;index as index">   
              {{f.label}}
              <div *ngIf="f.questions">  
              </div>
            </span>  
            <a title="" class="btn btn-sm btn-link  link-fw" hidden="true" data-toggle="tooltip"><i class="fa fa-fw fa-diamond" aria-hidden="true"></i> View Synthetrons</a>
            <a title="" class="btn btn-sm btn-link  link-fw" hidden="true" data-toggle="tooltip"><i class="fa fa-fw fa-list-alt" aria-hidden="true"></i> View Messages</a>
        </div>
        </div>
    </div>

    <div class="col mb-4">             
            
        <div class="card h-100">
        <div class="card-header bg-primary text-white">
            <h6 class="mb-0 pull-left"><i class="collapsable-lg fa fa-fw fa-check-square-o" aria-hidden="true"></i> <span *ngIf="disc.forms && disc.forms.length <= 2">Questionnaires</span>    </h6>             
        <ul class="nav nav-tabs card-header-tabs pull-right" id="nav-tab" role="tablist">
            <li role="presentation" *ngFor="let f of disc.forms;index as index" class="nav-item">
                <a class="nav-item nav-link {{index == 0 ? 'active' : ''}}" id="nav-{{f.timing}}-tab" data-toggle="tab" href="#nav-{{f.timing}}" role="tab"  aria-selected="true"><span class="timing {{index == 0 ? '' : 'text-light'}}">{{f.timing}}</span></a>
              </li>
        </ul>  
        </div>

        <div class="card-body tab-content">
          <!--use tabs and tabcontent for each form -->
          <div class="tab-pane fade show {{index == 0 ? 'active' : ''}}" *ngFor="let f of disc.forms;index as index" id="nav-{{f.timing}}" role="tabpanel">
              <p>This questionnaire has <span class="badge badge-pill {{!f.questions ? 'badge-danger' : 'badge-success'}}">{{!f.questions ? '0' : f.questions.length}}</span> questions.</p>
          </div>  
          <div *ngIf="!disc.forms"><small><i class="fa fa-lightbulb-o" aria-hidden="true"></i> Add questionnaires to present before and/or after your session.</small></div>
        </div>
        <div class="card-footer text-right">
          <small class="text-muted">Questionnaires: {{disc.forms?.length}}</small>

        </div>
      </div>     
    </div>
    <div class="col mb-4">    
        <div class="card h-100">
        <div class="card-header bg-primary text-white">
            <h6 class="mb-0"><i class="fa fa-fw fa-image" aria-hidden="true"></i> Media Assets</h6>
        </div>
        <div class="card-body mh">
            <small *ngIf="!disc.mediaAssets"><i class="fa fa-lightbulb-o" aria-hidden="true"></i> Add images & video's to present during your session or on a landingpage.</small>

          <ul class="list-group list-group-flush" *ngIf="disc.mediaAssets">
              <li class="list-group-item" *ngFor="let asset of disc.mediaAssets"> 
                <div class="row align-items-center no-gutters">
                <i class="fa text-dark col-1" aria-hidden="true" data-placement="top" data-toggle="tooltip" title="{{asset.type}}"></i>
                <a *ngIf="asset.type == 'JPG' || asset.type == 'PNG' || asset.type == 'GIF'"  class="col-3 text-center" data-placement="top" data-toggle="tooltip" title="Preview this image.">  
                 <img src="{{asset.url}}" class="rounded" width="40" height="40"/></a> 
                <a *ngIf="asset.type== 'YOUTUBE' || asset.type == 'VIMEO'" class="video-link col-3 text-center" href="{{asset.url}}" target="_blank"
                data-placement="top" data-toggle="tooltip" title="Play this video on the web."> 
                <i class="fa fa-2x" aria-hidden="true"></i></a>
              <span *ngIf="asset.type == 'YOUTUBEEMBED' || asset.type == 'VIMEOEMBED'" class="video-link col-3 text-center">
                <i  class="fa fa-code fa-2x text-dark" aria-hidden="true" data-placement="top" data-toggle="tooltip" title="This video is stored as Youtube embed code."></i>   
              </span>
              <span class="text-secondary text-capitalize col-8">{{asset.label}}</span>
              </div>
              </li>
          </ul>               
        </div>
        <div class="card-footer d-flex">
            <div class="w-50">
            <span data-toggle="tooltip" title="Manage Media Assets for this session"  ><i class="fa fa-fw fa-pencil" aria-hidden="true"></i> manage assets</span>                    
            </div>               
            <div class="w-50 text-right">
                <small class="text-muted">Assets: {{disc.mediaAssets?.length}}</small>
            </div>
        </div>
      </div>
    </div>
    <div class="col mb-4">    
     <div *ngIf="appUser.accountCoOwner && disc.account != undefined" class="card h-100">
      <div class="card-header bg-secondary text-light">
          <h6 class="mb-0"><i class="collapsable-lg fa fa-fw fa-building-o" aria-hidden="true"></i> Organised by <strong class="text-capitalize">{{disc.account.name}}</strong></h6>
        </div>
        <div class="card-body">
            <ul class="list-group list-group-flush mb-1">
              <li class="list-group-item pt-0" *ngIf="disc.account.branding != null">
                <div class="row no-gutters align-items-center">      
                    <div *ngIf="disc.account.branding.primaryLogo != undefined"  class="col-5">
                        <img src="{{disc.account.branding.primaryLogo.url}}" class="img-thumbnail" width="100" > 
                    </div>
                    <div  class="{{disc.account.branding.primaryLogo != undefined ? 'col-12' : 'col-7'}}">
                        
                    <div class="row no-gutters">                            
                        <div class="col-3">   
                            <i *ngIf="disc.account.branding.color1" class="fa fa-info-circle color-thumbnail" style="color:'{{disc.account.branding.color1}}'" aria-hidden="true" data-toggle="tooltip" title="This color is used for the text on the landingpage."></i>
                        </div>
                        <div class="col-2"> 
                            <i *ngIf="disc.account.branding.color2" class="fa fa-info-circle color-thumbnail" style="color:'{{disc.account.branding.color2}}'" aria-hidden="true" data-toggle="tooltip" title="This color is used for the buttons and links on the landingpage."></i>
                        </div>
                    </div>                        
                    </div>
                </div>
              </li>  
              <li class="list-group-item" *ngIf="disc.account.owner == null">
                  <i class="fa fa-fw fa-user-circle" aria-hidden="true" title="organisation owner"></i>{{disc.account.owner}}
              </li>
            </ul>   
                <a href="/discussions" title="List discussions of this account." class="btn btn-link btn-sm text-success link-fw"><i class="fa fa-fw fa-comments-o" aria-hidden="true"></i> View discussions</a> 
        </div>
         
        <div class="card-footer">
            <div class="w-50 pull-left">
                <span data-toggle="tooltip" title="Edit the options of this organisation." ><i class="fa fa-fw fa-pencil" aria-hidden="true"></i> edit <span class="collapsable-sm">organisation</span></span>                    
            </div>               
            <div class="w-50 pull-right text-right">
                <small class="text-muted">Updated: {{disc.account.modifiedDate | date:'dd MMM yyyy' }} </small>  
            </div>
            <div class="clearfix"></div>
        </div>
      </div>           
    </div>
    </div></div>
