import { Component, OnInit,Input } from '@angular/core';
import { AppUser } from '../model/appUser';
import { Discussion } from '../model/discussion';
import { ConversationApiService } from '../conversation-api-service';
import { Mode } from '../model/idiscussion';
import { Membership } from '../model/membership';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss']
})
export class DiscussionComponent implements OnInit {

  @Input('appUser') appUser ={} as AppUser;
  @Input('id') discussionId ='';
  @Input() activeAccount?: Membership;

  pageTitle='Discussion';
  disc = {} as Discussion;
  keys = Object.keys;
  values = Object.values;
  modes=this.keys(Mode);
  today = new Date
  mode=Mode;
  moderatorLink = '';
  messageSent = '';


  constructor(private api: ConversationApiService) { }

  ngOnInit(): void {
    var lsu = localStorage.getItem('appUser');
    if(lsu != undefined && (this.appUser == undefined || this.appUser.email == undefined)) {
      this.appUser = JSON.parse(lsu);
    } 


    if (this.discussionId) {
      this.api.getDiscussion(this.discussionId).subscribe(
        (response:Discussion) => {
          this.disc = response;
          console.info("discussions loaded.");
    
        }
      );
    }
    }
}
