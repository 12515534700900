import { Component, OnInit,Input  } from '@angular/core';
import { AppUser } from '../model/appUser';
import { Discussion } from '../model/discussion';
import { Mode, TimeRange } from '../model/idiscussion';
import { ConversationApiService } from '../conversation-api-service';
import {  Router } from '@angular/router';
@Component({
  selector: 'app-discussions',
  templateUrl: './discussions.component.html'
})
export class DiscussionsComponent implements OnInit
 {

  //timeRange! : TimeRange; 
  //@Input('timeRange')
  @Input()timeRange?:string;
   
  @Input('discussions') discussions?:Discussion[];
  @Input() appUser= {} as AppUser;
  @Input() selectAccountId?:string;
  //subscription: Subscription;

  
  //appUser = {} as AppUser;  
  itemCount:number=0;
  pages:number=0;
  pageTitle='Discussions';
  timeRanges =TimeRange;
  modes=Mode;
  noResults?:string;
  today = new Date;

  //constructor(private discussionApi: ConversationApiService) { }

  constructor(private discussionApi: ConversationApiService,private router: Router) {

    
    //this.timeRange = this.timeRanges.FROM_LAST_3_MONTHS; 
    // this.subscription = stateService.selectAccountId$.subscribe(
      
    //   selectAccountId => {
    //     console.info("active account change event received for: " +selectAccountId );
    //     this.selectAccountId = selectAccountId;
        
    //     this.get();
    // });
  }

  ngOnInit(): void {
    this.timeRange = "FROM_LAST_3_MONTHS";
    var lsu = localStorage.getItem('appUser');
    if(lsu != undefined && (this.appUser == undefined || this.appUser.email == undefined)) {
      this.appUser = JSON.parse(lsu);
      //if (this.appUser.activeAccount) this.selectAccountId  = this.appUser.activeAccount.id;
    } 
    this.get();
    
    
}

get():void { 
  if(!this.timeRange) return;

  var timeRangeTitle : TimeRange = TimeRange[this.timeRange as keyof typeof TimeRange];

  console.info("get discussions "+timeRangeTitle);

  //this.discussions = {} as Discussion[];
  
  var acid = this.selectAccountId ? this.selectAccountId : this.appUser.activeAccount ? this.appUser.activeAccount.id : undefined;
  if(acid) {
    console.log('get discussions for active account ..' + this.selectAccountId); 
    
    this.discussionApi.getDiscussions(this.timeRange, acid).subscribe(
      (discs:Discussion[]) => {
        if(discs) {
          this.discussions = discs;
          if(this.discussions.length > 0)  {
            this.noResults=undefined;
            console.info("discussions loaded.");
          }
          else this.noResults='No discussions '+ timeRangeTitle ; 
        }
      }
    );
  }
}

keyDescOrder(){
  return 0;
}

onSelected(accountId: string) {
  console.log('Recieve accountId change event from child: ' +accountId); 
  this.selectAccountId = accountId;
  this.get();
}

navigateToDashboard(discId:string){
  this.router.navigate(['/discussion/'+ discId]);
}



}
