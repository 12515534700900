import { Component, OnInit } from '@angular/core';
import { AppUser } from './model/appUser';
import { Membership } from './model/membership';
import { TimeRange } from './model/idiscussion';
import { UserApiService } from './user-api-service';
import { I18n } from 'aws-amplify';
import { RestAPI } from '@aws-amplify/api-rest';
import {environment}  from '../environments/environment';

I18n.putVocabulariesForLanguage('en', {
  'Sign In': 'Login', // Tab header
  'Sign in': 'Go', // Button label
  'Sign in to your account': 'Welcome Back',
  'Enter your Email': 'Your Go-staging email.',
  'Enter your Password': 'Your Go-staging password.', 
  'Forgot your password?': 'reset password',
});

RestAPI.configure({
  endpoints: [{
          name: "GoUserGw",
          endpoint: environment.endpoints.GoUserGw
      },{
        name: "GoDiscussionGw",
        endpoint: environment.endpoints.GoDiscussionGw
    },{
      name: "GoLandingpageGw",
      endpoint: environment.endpoints.GoLandingpageGw
  }]
});
// Hub.listen('auth', (data) => {
//   switch (data.payload.event) {
//     case 'signIn':
//         console.log('user signed in..');
//         break;
//     case 'signUp':
//         console.log('user signed up');
//         break;
//     case 'signOut':
//         console.log('user signed out');
//         break;
//     case 'signIn_failure':
//         console.log('user sign in failed');
//         break;
//     case 'configured':
//         console.log('the Auth module is configured');
//   }
// });

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [  "../styles.scss","./app.component.scss"]
})


export class AppComponent  {
  
  title = 'Go-Ng';
  appUser = {} as AppUser;  
  activeAccount?: Membership;
  keys = Object.keys;
  values = Object.values;
  timeRanges =this.keys(TimeRange);
  
  constructor(private userApi: UserApiService) { 
    
  }
  // constructor(private data: StateService) { 
  //   Amplify.configure(awsExports);
  //       Auth.currentSession()
  //         .then(session => {
  //           var idToken = session.getIdToken()
  //           data.jwtToken = idToken.getJwtToken();
  //           //localStorage.setItem("jwtToken", idToken.getJwtToken());
  //           console.log('jwt token obtained');
  //     });









}
