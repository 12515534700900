
<app-navigation [appUser]=appUser (selected)="onSelected($event)" active="discussions"></app-navigation>

<div class="container-lg">
    <h3 class="pull-left">
        <i class="fa fa-comments collapsable-sm" aria-hidden="true"></i>  {{pageTitle}} {{appUser.activeAccount?.name}} <span class="badge badge-primary">{{discussions?.length}}</span></h3>

    <a hidden *ngIf="appUser.systemAdmin"  title ="Check for newly imported discussions.." data-toggle="tooltip" data-placement="bottom" class="btn btn-outline-primary btn-sm pull-right btn-rounded mr-1 "><i class="fa fa-refresh" aria-hidden="true"></i></a>
    <div class="clearfix"></div> 
    <div class="card  page-list">
     <div class="row card-header">    
        <div class="col-7 align-items-center">




        </div>
        <div class="col-3 text-right text-nowrap">
            <div class="input-group">   
                <select class="form-control border-secondary"
                id="timeRange" title="Filter data by date-range."
                [(ngModel)]="timeRange" name="timeRange" (change)="get()">
                    <optgroup label="Filter by"></optgroup>       
                    <option *ngFor="let tr of timeRanges | keyvalue : keyDescOrder" [ngValue]="tr.key">{{tr.value}}</option>
                </select>  
                </div>
        </div>
    <div class="col-2 text-right text-nowrap">

        <a class="{{appUser.activeAccount != null && appUser.accountCoOwner ? '' : 'disabled'}}" title ="Add new Discussion" data-toggle="tooltip" 
                  class="btn btn-primary text-nowrap add-item-btn"><i class="fa fa-plus" aria-hidden="true"></i>
                  <span class="collapsable-sm"> Discussion</span></a>

                <div hidden class="btn-group" role="group">
                  <button type="button" *ngIf="appUser.accountCoOwner" 
                  class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span class="sr-only">Toggle Dropdown</span>
                    </button>
                  <div class="dropdown-menu" *ngIf="appUser.accountCoOwner">

                        <a href='' title ="Add with extra options." 
                        class="dropdown-item">Add Advanced</a>
                  </div>
                </div>

    </div>   

    </div>
    <div class="card-body">
     
        <div class="border rounded-lg border-light">

        <div class="alert alert-warning mb-0" *ngIf="noResults">{{noResults}}</div>
      
        <table id="sortable" class="table table-hover mb-0">
            <thead >
                <tr>
                    <th width="20%" scope="col" sort-col="key" class="text-nowrap">Name&nbsp;&nbsp;<i class="fa fa-sort" aria-hidden="true"></i></th>
                    <th width="10%" scope="col" sort-col="mode" class="text-nowrap">Mode&nbsp;&nbsp;<i class="fa fa-sort" aria-hidden="true"></i></th>
                    <th width="15%" scope="col" sort-col="account.name" *ngIf="appUser.activeAccount == null" class="text-nowrap">Organisation&nbsp;&nbsp;<i class="fa fa-sort" aria-hidden="true"></i></th>
                    <th width="20%" scope="col" sort-col="startDate" class=" text-nowrap">Start Date&nbsp;&nbsp;<i class="fa fa-sort" aria-hidden="true"></i></th>
                    <th width="11%" scope="col" class="collapsable-lg">Creator</th>                            

                    <th width="5%" scope="col" class="text-nowrap collapsable-sm" sort-col="language"><i class="fa fa-language" title="language of the discussion" aria-hidden="true"></i>&nbsp;&nbsp;<i class="fa fa-sort" aria-hidden="true"></i></th>

                </tr>
            </thead>
            <tbody>

          

                <tr *ngFor="let disc of discussions"  (click)="navigateToDashboard(disc.id)" class="{{disc.startDate == today ? 'bg-info' : ''}}" title="{{disc.startDate  == today ? 'This session takes place today' : ''}}">
     
                    <th scope="row" class="clickable">{{disc.label || disc.key}}</th>
                    <th scope="row">
                        <span class="badge badge-pill " *ngIf="disc.mode != modes.NOT_STARTED" class="{{disc.mode == modes.ENDED ? 'badge-secondary' : disc.mode == modes.STARTED ? 'badge-danger' : 'badge-primary'}}">{{disc.mode}}</span> 
                    </th>
                    <td class="" *ngIf="appUser.activeAccount == undefined">{{disc.account.name}}</td>
                    <td class="">{{disc.startDate | date:'dd MMM yyyy - HH:mm' }}</td>
                    <td class=" collapsable-lg" title="{{disc.creator}}" data-toggle="tooltip">{{disc.creator}}</td>                                

                    <td class=" collapsable-sm" data-placement="left" title="{{'language code: '+disc.language}}">{{disc.language}}</td>                    
             
                </tr>
      
            </tbody>
        </table>  </div>
    </div></div>
</div>
