import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment}  from '../environments/environment';
import { AppUser } from './model/appUser';
import { RestAPI } from '@aws-amplify/api-rest';
import { Auth, Amplify} from 'aws-amplify';
import awsExports from '../aws-exports';

var myInit = { 
  apiTimeout : 99,
  body : {},
  headers: {'x-api-key':environment.xApiKey,'Access-Token':''}, 
  response: true, // (return the entire Axios response object instead of only response.data)
};

@Injectable({
  providedIn: 'root',
})
export class UserApiService  {
  userProfile!: AppUser;
  //jwtToken!:string;
  authenticatedEmail = '';


  constructor() {

  }

  initToken() {
    Amplify.configure(awsExports);
    Auth.currentSession()
      .then(session => {
        myInit.headers['Access-Token']= session.getIdToken().getJwtToken();
        console.log('jwt token obtained');
     });
  }
  public getUserByEmail(email:String,retries = 2) {
    console.log("get user by email called: "+email);
    const user = new Observable<AppUser>(observer => {

      // if(this.data.appUser != undefined && this.data.appUser.email == email) {
      //   console.log("..returing preloaded user with same email")
      //   observer.next(this.data.appUser)
      //       observer.complete()
      // }
      setTimeout(() => {
        if(email != undefined && email != null && email.length > 3) {
          
        RestAPI.get('GoUserGw', '/user/email/'+email+ '/view', myInit)
          .then(r => {
            this.userProfile = r.data as AppUser;
            //this.data.appUser = this.userProfile; 
            observer.next(this.userProfile);
            observer.complete();
          })
          .catch(error => {

            if (retries == 0) {
              var em = "error fetching user profile: "+ error;
              observer.error(em);
              console.log(em);
              //this.router.navigate(['/user-signup/']);
              //this.createUser(environment.authenticatedEmail);
              throw error;
              
            } else {
              // apiCall with one less retry
              console.log('error, retry: '+retries);
              return this.getUserByEmail(email,--retries);
            }
            
     
        });
      }

    },myInit.apiTimeout);
    });
  return user;
  }


//   public createUser(userF:IUser) {
//     console.log("creating new user profile.." +userF.firstName);
    
//     var user = new User(environment.authenticatedEmail,"NL");
//     //user.email = environment.authenticatedEmail
//     user.firstName = userF.firstName
//     user.lastName = userF.lastName

//     //var group = {id:environment.go.group.id,name:environment.go.group.name,type:Type.GROUP} as Account;
//     console.log("about to create user "+user.email);
//     const u = new Observable<User>(observer => {
//       setTimeout(() => {
//         myInit.headers['Access-Token']= environment.jwtToken;
//         myInit.body = user;
//         RestAPI.post('GoUserGw', '/user/save', myInit)
//           .then((u:User) => {
//             environment.appUser = user 
//             observer.next(user)

//             this.createAccount({} as Account).subscribe(
//               (response:any) => {
//                 //var returnedAccount = {id:acc.data.id,name:acc.data.name,type:acc.data.type} as Account;
//                 //environment.account = returnedAccount;
//                 console.log("created account with id: "+response.data.id);
//                 observer.complete()
//               }
//             );

//           })
//           .catch(error => {
//             var em = "error creating new user and client account: "+ error;
//             observer.error(em);
//             console.log(em);
//         });
  
//     },myInit.apiTimeout);
//     });
//     return u;
// }

// public updateUser(user:User) {
//   console.log("initiate update user profile..");
  
//   const uuid = new Observable<String>(observer => {
//     setTimeout(() => {
//       myInit.body = user;
//       RestAPI.put('GoUserGw', '/user/', myInit)
//         .then(() => {
//         // this.goUser = r;
//           //observer.next(uuid)
//           console.log("User updated");
//           observer.complete()
//         })
//         .catch(error => {
//           var em = "error updating user: "+ error;
//           observer.error(em);
//           console.log(em);
//       });


//   },myInit.apiTimeout);
//   });
//   return uuid;
// }

// public createAccount(account:Account) {
//   console.log("creating new account for group: "+environment.go.group.name);
//   var account = new Account(account.name == undefined ? 'insi-client' : account.name ,environment.go.group.name,environment.go.group.id);
//   account.addMembership(environment.appUser,true,MembershipType.OWNER);
//   //environment.account = account;
//   console.log("abount to create: "+account.name);
//   const accId = new Observable<any>(observer => {
//     setTimeout(() => {
//       myInit.headers['Access-Token']= environment.jwtToken;
//       myInit.body = account;
//       RestAPI.post('GoUserGw', '/account', myInit)
//         .then((response:any) => {
//         // this.goUser = r;
//           observer.next(response.data)
//           console.log("account create success");
//           observer.complete()
//           this.router.navigate(['/']);
//           //console.log("Response: "+this.user.email);
//         })
//         .catch(error => {
//           var em = "error creating new go account: "+ error;
//           observer.error(em);
//           console.log(em);
//       });

//   },myInit.apiTimeout);
//   });
//   return accId;
// }
}
