import { Component, OnInit} from '@angular/core';
import { Discussion } from '../model/discussion';
import { ConversationApiService } from '../conversation-api-service';
import { AppUser } from '../model/appUser';
import { StateService } from '../state-service';
import { UserApiService } from '../user-api-service';
import { AuthenticatorService  } from '@aws-amplify/ui-angular';
import { AmplifyUser} from '@aws-amplify/ui';
import {  Router } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers:[StateService]
})

export class HomeComponent implements OnInit {
  user?: AmplifyUser;
  selectAccountId?:string;
  //@Input() selectAccountId?:string;
  appUser?: AppUser;
  timeRange:string = 'FROM_LAST_3_MONTHS';
  discussions:Discussion[] = [];
  noResults?:string;
  
  constructor(private authenticator: AuthenticatorService,private discussionApi: ConversationApiService,private userApi: UserApiService,private router: Router) {
    if(this.appUser &&  this.appUser.email != undefined) return;
    var o = localStorage.getItem('appUser');
    if(o != null &&  o != undefined) {
      this.appUser = JSON.parse(o);
      if(this.appUser){
        console.log('user profile stored locally');

      } 
    } else {
      this.user = this.authenticator.user;
      this.userApi.initToken();
      this.getUser(this.user.attributes?.email);

    }
    //this.getDiscussions();
    console.log('initial data bootstrap complete');
  }

    
  ngOnInit(): void {



    //if(this.appUser){
      this.getDiscussions();
      
      
    //}


   


}

private getDiscussions(selectAccountId?:string) {
  console.info("get homepage discussions");
  this.noResults='No discussions found for '+this.timeRange ;
  var acid = selectAccountId ?  selectAccountId : this.appUser?.activeAccount ? this.appUser.activeAccount.id : undefined;
  if(!acid){
    console.log('no accound id.');
    return;
  }
  this.discussionApi.initToken();
  this.discussionApi.getDiscussions(this.timeRange, acid).subscribe(
    (discs:Discussion[]) => {
      this.discussions = discs;
      if(this.discussions.length > 0)  {
        this.noResults=undefined;
        console.info("discussions loaded.");
      }
      else this.noResults='No discussions ' ; 




    }
  );
}
   
getUser(email?:string):void { 
  email = email ? email : this.appUser ? this.appUser.email : '';
  this.userApi.getUserByEmail(email).subscribe(
    (u:AppUser) => {
      console.log('user profile loaded: ' + u.email);
      this.appUser = u;
      this.selectAccountId = this.appUser.activeAccount?.id;
      //this.selected = this.appUser.activeAccount?.id;
      //this.selected.emit(this.selectAccountId);
      localStorage.setItem("appUser", JSON.stringify(u));
      this.getDiscussions(u.activeAccount?.id)
    }
  );
  }

  onSelected(accountId: string) {
    console.log('Recieve accountId change event from child: ' + accountId); 

    //this.selectAccountId = accountId;
    this.getDiscussions(accountId);
  }

  navigateToDashboard(discId:string){
    this.router.navigate(['/discussion/'+ discId]);
  }

  // onAuthenticated(authEmail: string) {
  //   if(this.appUser &&  this.appUser.email != undefined) return;
  //   console.info("change event for authenticated email: " +authEmail );
  //   this.appUser.email = authEmail;
  //   this.getUser();
  //   this.getDiscussions();
  
  // }
}
