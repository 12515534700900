<nav class="navbar navbar-expand-md fixed-top border-bottom">
    <div class="container-fluid">
        <a class="navbar-brand" routerLink="/home" data-placement="bottom" data-toggle="tooltip" title="Welcoming Screen">    </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    
        <div id="navbarSupportedContent" class="collapse navbar-collapse">
            
            <ul class="nav navbar-nav">    
                <li class="nav-item {{active != null &&  active.includes( 'discussion') ? 'active':''}}">
                    <a class="nav-link" title="Manage Synthetron sessions." routerLink="/discussions"><i class="fa fa-comments collapsable" aria-hidden="true"></i> <span> Discussions</span></a>
                </li>

             </ul>
    
            <nav class="nav navbar-nav navbar-right" *ngIf="active != 'profile' && active != 'discussion'">

                <select *ngIf="appUser" class="form-control form-control-sm border-secondary text-capitalize" 
                      title="Select an Organisation context to work in." form-control form-control-sm border-secondary text-capitalize
                      [(ngModel)]="selectAccountId" name="selectAccountId" (change)="select()">
                      <option *ngFor="let memb of appUser.accounts" [value]="memb.id.accountId" >{{ memb.name }}</option>
                    </select>

            </nav>
            <div class="mr-auto"></div>
                
            <ul class="nav navbar-nav navbar-right">      
                <li class="nav-item" *ngIf="appUser" class=" {{active != null &&  active === 'profile' ? 'active':''}}">
                    <a class="nav-link pr-0" title="Logged in as {{appUser.email}}"  routerLink="/profile"><i class="fa fa-user-circle" aria-hidden="true"></i> profile</a>
                </li>
                   <li class="nav-item">
                    <button (click)="logout()" class="nav-link btn btn-link" title="Logout"><i class="fa fa-sign-out" aria-hidden="true"></i>logout</button>
                 </li>
    

             
            </ul>
        </div>
    </div>
    </nav>

    
    
<nav class="navbar navbar-expand-md navbar-light fixed-bottom collapsable-sm">
    <div class="container-fluid">
        
        <div id="navbarSupportedContent" class="collapse navbar-collapse text-secondary">
            <div class="w-25"><small><i class="fa fa-copyright" aria-hidden="true"></i> {{null | date:'yyyy' }} <a href="https://synthetron.com/" class="text-primary" title="Synhetron website." target="_blank">Synthetron NV</a></small></div>
            <div class="w-50 text-center font-weight-bold" *ngIf="appUser" >
                <small *ngIf="appUser.activeGroup != undefined"><i class="fa fa-sitemap text-secondary" aria-hidden="true"></i> {{appUser.activeGroup.name}} </small>
                <small *ngIf="appUser.activeAccount != undefined" title="Active Organisation." data-toggle="tooltip" > <i class="fa fa-chevron-circle-right text-secondary" aria-hidden="true"></i> {{appUser.activeAccount.name || ''}} </small>
                <small *ngIf="appUser.activeAccount != undefined" title="Your role in this organisation." data-toggle="tooltip">   <i class="fa fa-user-circle" aria-hidden="true"></i> {{appUser.activeAccount.membershipType}} </small>
                 
            </div>
            
            <div class="w-25 text-right"><small title="Application version: {{appVersion}}">{{appVersion}} </small></div>
        </div>
    </div>
    </nav>