import { Component, OnInit, Input,Output,EventEmitter,OnChanges ,SimpleChanges } from '@angular/core';
import {Auth} from 'aws-amplify';
import { Router } from '@angular/router';
import { AppUser } from '../model/appUser';
import { StateService } from '../state-service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  providers:[StateService]
})


export class NavigationComponent implements OnInit  {

  @Input() appUser?: AppUser;
  //@Input() activeAccount= {} as Membership;
  @Input() active:any;
  @Input() selectAccountId:any; // to catch changes from home or another parent cpmponent
  @Output() selected = new EventEmitter<string>();
  //timeRange =TimeRangeSimple;
  // timeRange =this.keys(TimeRange);
  //activeAccount = this.appUser.activeAccount.id;
  appVersion = "Node.js thin client"; 
  moderatedId = "xxx";
  //constructor(private router: Router,private stateService: StateService) { 
  constructor(private router: Router) { }

  ngOnInit(): void {

    // if(this.appUser && this.appUser.activeAccount) {
      
    //   //this.activeAccount = this.appUser.activeAccount;
     //  this.selectAccountId = this.appUser.activeAccount.id;
    //   console.log('set active account from appUser: '); 
    // }
    if (this.appUser && this.appUser.activeAccount) this.selectAccountId  = this.appUser.activeAccount.id;
   //if (this.activeAccount == undefined && this.appUser.activeAccount != undefined) this.activeAccount  = this.appUser.activeAccount.id;

   if(this.selectAccountId){
    console.log('inut selectaccountid : ' + this.selectAccountId); 
  //   this.authenticatedEmail.emit(this.user.attributes?.email);

   }

      
  }

  select() {
    if(this.selectAccountId) {
      console.log('Emit accountId change event to parent: ' + this.selectAccountId); 
      //this.stateService.emitActiveAccount(this.selectAccountId);
      this.selected.emit(this.selectAccountId);
    }

    // if(this.appUser != undefined) {
    //   //var newAccount  = {} as Membership;  
    //   var selectedMemb = this.appUser.accounts?.find(o => o.id.accountId === this.activeAccount);
    //   if(selectedMemb != undefined) this.appUser.activeAccount = new Account(selectedMemb);
    // }
  }

  logout() {
  
    //this.data.clear();
    console.log("Logout Clicked");
    localStorage.removeItem('appUser');
    //localStorage.removeItem('jwtToken');
    Auth.signOut({ global: false })
      .then(data => {
        
        //console.log(data); 
        this.router.navigate(['/']);
      })
      .catch(err => console.log(err));
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if(changes['selectAccountId'] && this.selectAccountId) {
  //    var previous= changes['selectAccountId'].previousValue;
  //    var current= changes['selectAccountId'].currentValue;
  //       console.log("Input select account id Changes, old value: "+previous);
  //   console.log("Input select account id Changes, new value: "+current + ", input var value: "+ this.selectAccountId);
  //   }
  // }
}
