import { NgModule } from '@angular/core';

import { Amplify, } from 'aws-amplify';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import awsconfig from '../aws-exports';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationComponent } from './navigation/navigation.component';
import { DiscussionSearchBoxComponent } from './discussion-search-box/discussion-search-box.component';
import { DiscussionComponent } from './discussion/discussion.component';
import { DiscussionsComponent } from './discussions/discussions.component';
import { ProfileComponent } from './profile/profile.component';

Amplify.configure(awsconfig);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    DiscussionsComponent,
    DiscussionComponent,
    DiscussionSearchBoxComponent,
    NavigationComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AmplifyAuthenticatorModule,
    ReactiveFormsModule,
    FormsModule
   // BrowserAnimationsModule

  ],    
  // providers: [
  //   {
  //     provide: APP_INITIALIZER,
  //     useFactory: initSynchronousFactory,
  //   },
  // ],
  bootstrap: [AppComponent]
})
export class AppModule {  
  constructor() {

  }
    



}
